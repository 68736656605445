import React, {useEffect, useState} from "react";
import ExtensionSkeleton from "../Skeletons/ExtensionSkeleton/ExtensionSkeleton";
import Extension from "../Extension/Extension";
import axiosInstance from "../../utils/axiosInstance";
import {useSelector} from "react-redux";

function FriendCollection(props) {

    const selectedEdition = useSelector((state) => state.edition.selectedEdition)

    const [extensions, setExtensions] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        const fetchItems = async () => {
            try {
                const response = await axiosInstance.get(`/friends/extensions/${props.uniqidFriend}`)
                setExtensions(response.data)
            } catch (err) {
                console.error('Erreur lors de la récupération des extensions :', err)
                setError('Impossible de charger les extensions. Veuillez réessayer plus tard.')
            }

            setLoading(false)
        }

        fetchItems()
    }, [selectedEdition])

    return (
        <div>
            <h2 className="text-3xl font-semibold">Collection de {props.usernameFriend}</h2>
            <img
                src={props.imageFriend}
                alt={`${props.usernameFriend} profile`}
                className="w-20 h-20 rounded-full mx-auto my-4"
            />

            {loading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
                    {[...Array(11)].map((_, index) => (
                        <ExtensionSkeleton key={index} />
                    ))}
                </div>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
                    {extensions.map((item) => (
                        <Extension key={item.uniqid} name={item.name} code={item.code} total={item.total}
                                   count={item.count} slug={item.slug} isLink={false}/>
                    ))}
                </div>
            )}
        </div>
    )
}

export default FriendCollection