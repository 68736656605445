import React, { useState } from 'react'
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setEdition } from '../../redux/editionSlice'
import { logout } from '../../redux/authSlice'
import logo from '../../logo.svg'
import defaultImage from "../../assets/profiles/default.png"
import sanjiLove from '../../assets/profiles/sanji_love.png'
import chopper from '../../assets/profiles/chopper.png'
import nami from '../../assets/profiles/nami.png'
import zoro from '../../assets/profiles/zoro.png'
import ja from '../../assets/flags/ja.svg'
import en from '../../assets/flags/en.svg'

const profileImageMap = {
    default: defaultImage,
    sanji_love: sanjiLove,
    chopper: chopper,
    nami: nami,
    zoro: zoro
};

function Header() {
    const { isAuthenticated, user } = useSelector((state) => state.auth)
    const selectedEdition = useSelector((state) => state.edition.selectedEdition)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const profileImage = user && user.image ? profileImageMap[user.image] : profileImageMap['default']

    const handleLogout = () => {
        dispatch(logout())
        navigate('/login')
    }

    const handleEditionChange = (value) => {
        dispatch(setEdition(value.value))
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    const options = [
        { value: 'ja', label: <div><img src={ja} alt="Japanese" style={{ width: 40, marginRight: 10 }} /></div> },
        { value: 'en', label: <div><img src={en} alt="English" style={{ width: 40, marginRight: 10 }} /></div> }
    ]

    const selectedOption = options.find(option => option.value === selectedEdition)

    return (
        <nav className="bg-blue-400 text-white shadow-md">
            <div className="container mx-auto px-4 py-3 flex justify-between items-center">
                <Link to="/" className="text-2xl font-bold flex items-center">
                    <img src={logo} alt="Logdex" className="w-8 h-8 App-logo" />
                    <span className="ml-2">Logdex</span>
                </Link>

                <div className="hidden md:flex items-center space-x-6 ml-auto">
                    {isAuthenticated && (
                        <>
                            <Select
                                options={options}
                                onChange={handleEditionChange}
                                value={selectedOption}
                                isSearchable={false}
                                className="min-w-[120px]"
                            />

                            <div className="relative">
                                <button onClick={toggleDropdown} className="relative w-15 h-14 rounded-full border-2 border-white focus:outline-none hover:opacity-80">
                                    <img src={profileImage} alt="Profile" className="w-full h-full object-cover rounded-full" />
                                </button>

                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 bg-white text-gray-800 rounded shadow-lg w-48 z-50">
                                        <ul className="py-2">
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <Link to="/profile">Modifier Profil</Link>
                                            </li>
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <Link to="/community">Communauté</Link>
                                            </li>
                                            <li
                                                className="px-4 py-2 text-red-600 hover:bg-gray-100 cursor-pointer"
                                                onClick={handleLogout}
                                            >
                                                Déconnexion
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {isAuthenticated && (
                    <button
                        className="md:hidden text-white focus:outline-none"
                        onClick={toggleMobileMenu}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                )}
            </div>

            {isMobileMenuOpen && isAuthenticated && (
                <div className="md:hidden bg-blue-500 text-white py-4">
                    <div className="flex flex-col items-center">
                        <img src={profileImage} alt="Profile" className="w-16 h-16 object-cover rounded-full mb-4"/>
                        <div className="w-fit mb-4">
                            <Select
                                options={options}
                                onChange={handleEditionChange}
                                value={selectedOption}
                                isSearchable={false}
                            />
                        </div>
                        <Link to="/profile" className="py-2 text-lg hover:bg-blue-600 w-full text-center">Modifier
                            Profil</Link>
                        <Link to="/community"
                              className="py-2 text-lg hover:bg-blue-600 w-full text-center">Communauté</Link>
                        <button
                            onClick={handleLogout}
                            className="py-2 text-lg text-red-500 hover:bg-blue-600 w-full text-center"
                        >
                            Déconnexion
                        </button>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Header
