import React, {useEffect, useState, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import Extension from "../../components/Extension/Extension";
import Card from "../../components/Card/Card";
import {useSelector} from "react-redux";
import CardSkeleton from "../../components/Skeletons/CardSkeleton/CardSkeleton";

function Profile() {

    const {slug} = useParams()
    const navigate = useNavigate()
    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const selectedEdition = useSelector((state) => state.edition.selectedEdition)
    const hasMounted = useRef(false)

    const [filterType, setFilterType] = useState('')
    const [filterAlternative, setFilterAlternative] = useState(false)
    const [filterDouble, setFilterDouble] = useState(false)
    const [filterOwned, setFilterOwned] = useState(false)
    const [filterMissing, setFilterMissing] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axiosInstance.get(`/card/extension/${slug}`);
                setCards(response.data);
            } catch (err) {
                setError('Impossible de charger les cartes. Veuillez réessayer plus tard.');
            } finally {
                setLoading(false);
            }

        }

        fetchItems()
    }, [])

    useEffect(() => {
        if (hasMounted.current) {
            navigate('/');
        } else {
            hasMounted.current = true;
        }
    }, [selectedEdition, navigate])

    const getFilteredCards = () => {
        return cards.filter((card) => {
            const matchesType = filterType ? card.type === filterType : true;
            const matchesAlternative = filterAlternative ? card.isAlternative : true;
            const matchesDouble = filterDouble ? card.count > 1 : true;
            const matchesOwned = filterOwned ? card.count >= 1 : true;
            const matchesMissing = filterMissing ? card.count < 1 : true;
            const matchesSearch = card.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                card.number.toString().includes(searchQuery);
            return matchesType && matchesAlternative && matchesDouble && matchesOwned && matchesMissing && matchesSearch;
        })
    }

    const filteredCards = getFilteredCards()

    const handleCardCountChange = (uniqid, newCount) => {
        setCards((prevCards) =>
            prevCards.map((card) =>
                card.uniqid === uniqid ? { ...card, count: newCount } : card
            )
        )
    }

    return (
        <div className="mb-12 pb-12">
            <div className="my-10">
                <Extension code={slug.toUpperCase()} isLink={false}/>
            </div>

            <div
                className="flex flex-col items-center content-center justify-center sm:flex-row p-4 rounded-2xl shadow mx-5 text-black mb-10 bg-blue-400 w-fit mx-auto">
                <select
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-40 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3 md:mb-0"
                >
                    <option value="">Tous les types</option>
                    <option value="Common">Common</option>
                    <option value="Uncommon">Uncommon</option>
                    <option value="Rare">Rare</option>
                    <option value="Super Rare">Super Rare</option>
                    <option value="Secret Rare">Secret Rare</option>
                    <option value="Don">Don</option>
                </select>

                <label className="flex items-center gap-2 mx-5 my-2 md:my-0">
                    <input
                        type="checkbox"
                        checked={filterAlternative}
                        onChange={() => setFilterAlternative(!filterAlternative)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <span className="text-white font-bold">Parallèles seulement</span>
                </label>

                <label className="flex items-center gap-2 mx-5 my-2 md:my-0">
                    <input
                        type="checkbox"
                        checked={filterDouble}
                        onChange={() => {
                            setFilterOwned(false)
                            setFilterMissing(false)
                            setFilterDouble(!filterDouble)
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <span className="text-white font-bold">Doublons seulement</span>
                </label>
                <label className="flex items-center gap-2 mx-5 my-2 md:my-0">
                    <input
                        type="checkbox"
                        checked={filterOwned}
                        onChange={() => {
                            setFilterDouble(false)
                            setFilterMissing(false)
                            setFilterOwned(!filterOwned)
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <span className="text-white font-bold">Possédées seulement</span>
                </label>
                <label className="flex items-center gap-2 mx-5 my-2 md:my-0">
                    <input
                        type="checkbox"
                        checked={filterMissing}
                        onChange={() => {
                            setFilterDouble(false)
                            setFilterOwned(false)
                            setFilterMissing(!filterMissing)
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <span className="text-white font-bold">Manquantes seulement</span>
                </label>

                <input
                    type="text"
                    placeholder="Rechercher par nom ou numéro"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="p-2 border border-gray-300 rounded w-full sm:w-auto mx-5 mt-3 md:mt-0"
                />
            </div>

            {loading ? (
                <div className="flex flex-wrap justify-center gap-4 p-0 md:p-4 sm:p-0">
                    {[...Array(30)].map((_, index) => (
                        <CardSkeleton key={index}/>
                    ))}
                </div>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="flex flex-wrap justify-center gap-4 p-4 sm:p-0">
                    {filteredCards.length > 0 ? (
                        filteredCards.map((item) => (
                            <Card
                                key={item.uniqid}
                                uniqid={item.uniqid}
                                name={item.name}
                                number={item.number}
                                type={item.type}
                                image={item.image}
                                count={item.count}
                                isAlternative={item.isAlternative}
                                onCountChange={handleCardCountChange}
                            />
                        ))
                    ) : (
                        <p className="text-gray-500">Aucune carte trouvée avec les filtres actuels.</p>
                    )}
                </div>
            )}
        </div>
    )
}

export default Profile