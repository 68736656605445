import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from "../../redux/authSlice"
import axiosInstance from '../../utils/axiosInstance'
import defaultImage from '../../assets/profiles/default.png'
import sanjiLove from '../../assets/profiles/sanji_love.png'
import chopper from '../../assets/profiles/chopper.png'
import nami from '../../assets/profiles/nami.png'
import zoro from '../../assets/profiles/zoro.png'

const profileImageMap = {
    default: defaultImage,
    sanji_love: sanjiLove,
    chopper: chopper,
    nami: nami,
    zoro: zoro
};

const profileImageKeys = Object.keys(profileImageMap);

function Profile() {
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        pseudo: '',
        email: '',
        password: '',
        image: ''
    })

    const [error, setError] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    useEffect(() => {
        if (user) {
            setFormData({
                username: user.username || '',
                email: user.email || '',
                password: '',
                image: user.image || 'default',
            })
        }
    }, [user])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleImageSelect = (image) => {
        setFormData({ ...formData, image: image })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axiosInstance.post('/user', formData)

            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', response.data.token);
            dispatch(updateUser(response.data.user));

            setSuccessMessage('Profil mis à jour avec succès')
            setError('')
        } catch (err) {
            setError('Une erreur est survenue lors de la mise à jour du profil.')
            setSuccessMessage('')
        }
    };

    return (
        <div className="container mx-auto px-4 py-6">
            <h1 className="text-3xl font-bold text-center mb-6">Modifier votre profil</h1>

            {error && <div className="text-red-500 text-center mb-4">{error}</div>}
            {successMessage && <div className="text-green-500 text-center mb-4">{successMessage}</div>}

            <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 rounded shadow text-black">
                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-bold mb-2">Pseudo</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-bold mb-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="password" className="block text-sm font-bold mb-2">Nouveau mot de passe</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded"
                        placeholder="Laissez vide pour ne pas changer"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2">Image de profil</label>
                    <div className="flex space-x-4">
                        {profileImageKeys.map((key) => (
                            <div
                                key={key}
                                onClick={() => handleImageSelect(key)}
                                className={`w-16 h-16 rounded-full border-4 cursor-pointer ${
                                    formData.image === key
                                        ? 'border-blue-500'
                                        : 'border-gray-300'
                                }`}
                            >
                                <img
                                    src={profileImageMap[key]}
                                    alt={key}
                                    className="w-full h-full object-cover rounded-full"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-6">
                    <button
                        type="submit"
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Sauvegarder
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Profile;
