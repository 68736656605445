import React from 'react';

const ExtensionSkeleton = () => {
    return (
        <div>
            <div
                className="flex flex-col items-center justify-center rounded-2xl shadow px-2 py-3 text-center font-bold relative mx-auto animate-pulse"
                style={{height: '200px', maxWidth: '800px'}}>

                <div
                    className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 rounded-2xl animate-pulse"
                    style={{zIndex: 2}}
                ></div>

                <div className="z-20 flex flex-col items-center justify-center space-y-2">
                    <div className="h-4 bg-gray-400 rounded w-24 mx-auto"></div>
                    <div className="h-4 bg-gray-400 rounded w-20 mx-auto"></div>
                    <div className="h-4 bg-gray-400 rounded w-16 mx-auto"></div>
                </div>
            </div>
        </div>
    );
};

export default ExtensionSkeleton;
