import React from 'react';

const CardSkeleton = () => {
    return (
        <div className="relative pointer mx-2 group max-w-[150px] md:max-w-[200px] flex-grow animate-pulse anime-fadeOutScale" style={{width: '200px', height: '280px'}}>
            <div className="min-w-[155px] md:min-w-[175px] h-[220px] md:h-[260px] bg-gray-700 rounded"></div>

            <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-50 text-white rounded transition-opacity duration-300">
                <div className="text-center px-4 space-y-2">
                    <div className="h-4 bg-gray-400 rounded"></div>

                    <div className="h-4 bg-gray-400 rounded"></div>

                    <div className="h-4 bg-gray-400 rounded"></div>

                    <div className="h-8 w-8 bg-gray-400 rounded-full mx-auto"></div>

                    <div className="flex items-center justify-center space-x-2 mt-1">
                        <div className="h-8 w-8 bg-gray-400 rounded"></div>
                        <div className="h-8 w-16 bg-gray-400 rounded"></div>
                        <div className="h-8 w-8 bg-gray-400 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSkeleton;
