import React, { useEffect, useState } from 'react'
import Extension from "../../components/Extension/Extension"
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axiosInstance from '../../utils/axiosInstance'
import backgroundIsland from '../../assets/bg-island.png'
import Stats from "../../components/Stats"
import ExtensionSkeleton from "../../components/Skeletons/ExtensionSkeleton/ExtensionSkeleton"
import StatsSkeleton from "../../components/Skeletons/StatsSkeleton/StatsSkeleton"


function Home() {
    const { user, isAuthenticated } = useSelector((state) => state.auth)
    const selectedEdition = useSelector((state) => state.edition.selectedEdition)
    const navigate = useNavigate()

    const [extensions, setExtensions] = useState([])
    const [stats, setStats] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axiosInstance.get('/extension')
                setExtensions(response.data)
            } catch (err) {
                console.error('Erreur lors de la récupération des extensions :', err)
                setError('Impossible de charger les extensions. Veuillez réessayer plus tard.')
            }

            try {
                const response = await axiosInstance.get('/card/stats')
                setStats(response.data)
            } catch (err) {
                console.error('Erreur lors de la récupération des statistiques :', err)
                setError('Impossible de charger les statistiques. Veuillez réessayer plus tard.')
            } finally {
                setLoading(false)
            }
        };

        fetchItems();
    }, [isAuthenticated, navigate, selectedEdition])

    if (!isAuthenticated) return null

    return (
        <div className="mb-10">
            <div
                className="text-black p-4 rounded shadow-lg p-5 m-5 rounded-lg p-10"
                style={{
                    backgroundImage: `url(${backgroundIsland}),linear-gradient(to bottom, #60C0FF 0%, #DCF1FF 64%)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: "cover"
                }}
            >
                <h1 className="text-3xl font-bold">Bienvenue {user && user.username}! 🏴‍☠️</h1>
                <p className="mt-2 text-lg">Tu viens d’embarquer sur le Grand Dashboard de Logdex, le navire amiral de
                    la gestion des collections.</p>
                <p className="mt-2">Ici, dans les eaux tranquilles de la page principale, tu découvriras les trésors
                    statistiques globaux de ta collection, une véritable carte au trésor pour évaluer sa richesse et sa
                    grandeur. Si l’envie te prend de gouverner chaque détail, tu peux naviguer par extension.</p>
            </div>
            <div className="mx-5 mt-10">
                <h2 className="text-4xl mb-6 text-center">Résumé de ta collections</h2>
                <div className="flex flex-wrap justify-around">
                    {loading ? (
                        <div className="flex flex-wrap items-center justify-center">
                            {[...Array(8)].map((_, index) => (
                                <StatsSkeleton key={index} />
                            ))}
                        </div>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : (
                        <div className="flex flex-wrap items-center justify-center">
                            {stats.map((item, key) => (
                                <Stats key={key} count={item.total} type={item.type}/>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="mx-5 mt-10">
                <h2 className="text-4xl mb-6 text-center">Extensions disponibles</h2>
                {loading ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
                        {[...Array(11)].map((_, index) => (
                            <ExtensionSkeleton key={index} />
                        ))}
                    </div>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
                        {extensions.map((item) => (
                            <Extension key={item.uniqid} name={item.name} code={item.code} total={item.total}
                                       count={item.count} slug={item.slug} isLink={true}/>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Home