import React from "react";
import backgroundCommon from '../../assets/stats/bg-common.png'
import backgroundLeader from '../../assets/stats/bg-leader.png'
import backgroundSuperRare from '../../assets/stats/bg-super-rare.png'
import backgroundRare from '../../assets/stats/bg-rare.png'
import backgroundUncommon from '../../assets/stats/bg-uncommon.png'
import backgroundAlternative from '../../assets/stats/bg-alternative.png'
import backgroundDon from '../../assets/stats/bg-don.png'
import backgroundSecretRare from '../../assets/stats/bg-secret-rare.png'

function Stats (props) {

    const backgroundColorMapping = {
        default: '#776f6e',
        Common: '#776f6e',
        Uncommon: '#3e7fd3',
        Rare: '#772727',
        SuperRare: '#10541e',
        SecretRare: '#6c1585',
        Leader: '#2f2525',
        Alternative: '#d7c31f',
        Don: '#000000'
    };

    const backgroundImgMapping = {
        default: backgroundCommon,
        Leader: backgroundLeader,
        Rare: backgroundRare,
        SuperRare: backgroundSuperRare,
        Uncommon: backgroundUncommon,
        Common: backgroundCommon,
        Alternative: backgroundAlternative,
        Don: backgroundDon,
        SecretRare: backgroundSecretRare
    };

    const backgroundColor = backgroundColorMapping[props.type.replace(/\s+/g, '')] || backgroundColorMapping.default;
    const backgroundImage = backgroundImgMapping[props.type.replace(/\s+/g, '')] || backgroundImgMapping.default;

    return (
        <div>
            <span
                className="m-5 p-3 rounded-2xl shadow-xl sm:w-full md:w-auto flex flex-col items-center justify-center text-center relative animate-fadeInScale"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: 'center',
                    width: '130px',
                    height: '130px'
                }}
            >
                <div
                    className="rounded-2xl opacity-80 transition"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: backgroundColor,
                        zIndex: 10
                    }}
                />
                 <div className="z-20 flex flex-col items-center justify-center font-bold">
                    <span className="text-2xl">{props.count ?? 0}</span>
                     {props.type}
                </div>
                </span>
        </div>
    )
}

export default Stats