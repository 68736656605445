import React, {useEffect, useState, useRef} from 'react';
import { AiOutlineSearch, AiFillStar } from 'react-icons/ai';
import axiosInstance from "../../utils/axiosInstance";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Card(props) {

    const [isHovered, setIsHovered] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const [localCount, setLocalCount] = useState(props.count || 0)
    const hasMounted = useRef(false);

    const BASE_URL = process.env.REACT_APP_IMG_URL;
    const getImageUrl = (imageName) => `${BASE_URL}/${imageName}`;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (hasMounted.current) {
            updateCard();
        } else {
            hasMounted.current = true;
        }
    }, [localCount]);

    const updateCard = async () => {
        try {
            await axiosInstance.post(`/card/update`, {uniqid: props.uniqid, count: localCount})
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleIncrease = () => {
        setLocalCount(localCount + 1);
        if (props.onCountChange) {
            props.onCountChange(props.uniqid, localCount + 1);
        }
    };

    const handleDecrease = () => {
        if (localCount > 0) {
            setLocalCount(localCount - 1);
            if (props.onCountChange) {
                props.onCountChange(props.uniqid, localCount - 1);
            }
        }
    };

    const handleInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0) {
            setLocalCount(value);
            if (props.onCountChange) {
                props.onCountChange(props.uniqid, value);
            }
        }
    };

    return(
        <div>
            <div
                className="relative pointer mx-2 group max-w-[150px] md:max-w-[200px] flex-grow animate-fadeInScale"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => setIsHovered(!isHovered)}
            >
                {localCount > 0 && !isLoading && (
                    <div
                        className="absolute -top-2 -left-2 bg-blue-500 text-white rounded-full text-sm font-bold w-6 h-6 flex items-center justify-center border-2 border-white shadow-md z-10">
                        {localCount}
                    </div>
                )}

                {props.isAlternative && !isLoading && (
                    <div
                        className="absolute -top-2 -right-2 bg-yellow-500 text-white rounded-full text-sm font-bold w-6 h-6 flex items-center justify-center border-2 border-white shadow-md z-10">
                        <AiFillStar/>
                    </div>
                )}

                <LazyLoadImage
                    src={props.image && getImageUrl(props.image)}
                    loading="lazy"
                    alt="Card"
                    afterLoad={() => setIsLoading(false)}
                    className={`rounded transition-opacity duration-300 ease-in-out ${
                        isHovered ? 'opacity-60' : ''
                    } group-hover:opacity-60 ${localCount === 0 ? 'opacity-20' : ''}`}
                />

                <div
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setIsFullscreen(false);
                        }
                    }}
                    className={`absolute w-100 inset-0 flex justify-center items-center opacity-0 ${isHovered ? 'opacity-100 pointer-events-auto' : 'group-hover:opacity-100 pointer-events-none'} transition-opacity duration-300 ease-in-out bg-black bg-opacity-50 text-white rounded`}>
                    <div className="text-center px-4">
                        <h3 className="text-lg font-bold">{props.name}</h3>
                        <p>{props.number}</p>
                        <p>{props.type}</p>

                        <div className="my-2">
                            <button
                                className="text-white hover:text-gray-300"
                                onClick={() => {
                                    setIsFullscreen(true)
                                    setIsHovered(false)
                                }}
                            >
                                <AiOutlineSearch className="w-6 h-6"/>
                            </button>
                        </div>

                        <div className="my-2">
                            <label htmlFor="quantity" className="block text-sm">Quantité:</label>
                            <div className="flex items-center justify-center space-x-2 mt-1">
                                <button
                                    className="bg-gray-700 text-white rounded px-2 py-1"
                                    onClick={handleDecrease}
                                >
                                    -
                                </button>
                                <input
                                    id="quantity"
                                    type="number"
                                    value={localCount}
                                    onChange={handleInputChange}
                                    className="w-16 text-center border-none rounded bg-gray-700 text-white"
                                    min="1"
                                />
                                <button
                                    className="bg-gray-700 text-white rounded px-2 py-1"
                                    onClick={handleIncrease}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isFullscreen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsFullscreen(false)}
                >
                    <div
                        className="relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setIsFullscreen(false)}
                            className="absolute top-4 right-4 text-white text-3xl font-bold bg-black bg-opacity-50 p-2 rounded-full"
                        >
                            &times;
                        </button>
                        <img
                            src={props.image && getImageUrl(props.image)}
                            alt="Full Screen Image"
                            className="max-w-full max-h-screen object-contain"
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Card;