import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import PrivateRoute from './components/PrivateRoute';
import Cardlist from "./pages/Cardlist";
import Error from "./components/Error";
import FriendList from "./pages/FriendList";

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/collection/:slug" element={<PrivateRoute><Cardlist /></PrivateRoute>} />
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route path="/community" element={<PrivateRoute><FriendList /></PrivateRoute>} />
                <Route path="/*" element={<Error />} />
            </Routes>
        </Router>
    );
};

export default App;
