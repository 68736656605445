import { createSlice } from '@reduxjs/toolkit';

const initialEdition = localStorage.getItem('selectedEdition') || 'ja';

const editionSlice = createSlice({
    name: 'edition',
    initialState: {
        selectedEdition: initialEdition
    },
    reducers: {
        setEdition: (state, action) => {
            state.selectedEdition = action.payload;
            localStorage.setItem('selectedEdition', action.payload);
        },
    }
});

export const { setEdition } = editionSlice.actions;

export default editionSlice.reducer;