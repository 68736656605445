import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import axiosInstance from '../../utils/axiosInstance';
import backgroundIsland from '../../assets/bg-island.png'
import luffy from '../../assets/luffy.png'

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axiosInstance.post('/auth/signin', { username, password });
            const { token, user } = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(login(user));

            navigate('/');
        } catch (err) {
            setError('Identifiants incorrects. Veuillez réessayer.');
        }
    };

    return (
        <div
            className="flex justify-center items-center py-10 sm:py-4 shadow relative"
            style={{
                backgroundImage: `url(${backgroundIsland}),linear-gradient(to bottom, #60C0FF 0%, #DCF1FF 64%)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
                height: '800px'
            }}
        >
            <img src={luffy} alt="Luffy" className="absolute" style={{width:'800px'}}/>
            <div className="w-full max-w-md h-fit bg-white bg-opacity-80 rounded-3xl shadow2xl p-8 z-10">
                {error && (
                    <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="username"
                            className="block text-black font-medium mb-2"
                        >
                            Nom d'utilisateur
                        </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Entrez votre nom d'utilisateur"
                            className="w-full px-4 py-2 border-none shadow-md rounded-xl focus:ring focus:ring-blue-300 text-black"
                            required
                        />
                    </div>

                    <div className="mb-10">
                        <label
                            htmlFor="password"
                            className="block text-black font-medium mb-2"
                        >
                            Mot de passe
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Entrez votre mot de passe"
                            className="w-full px-4 py-2 border-none shadow-md rounded-xl focus:ring focus:ring-blue-300 text-black"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-xl hover:bg-blue-700 transition font-bold"
                    >
                        Se connecter
                    </button>
                </form>

                <p className="text-gray-600 mt-4 text-center">
                    C'est une bêta fermée pour l'instant, mais ne t'inquiète pas, tu pourras bientôt créer un compte.
                </p>
            </div>
        </div>
    );
};

export default Login;
