import React, {useState} from 'react';
import {Link} from "react-router-dom";
import OP10 from '../../assets/extensions/OP10.png'
import OP09 from '../../assets/extensions/OP09.png'
import OP08 from '../../assets/extensions/OP08.png'
import OP07 from '../../assets/extensions/OP07.png'
import OP06 from '../../assets/extensions/OP06.png'
import OP05 from '../../assets/extensions/OP05.png'
import OP04 from '../../assets/extensions/OP04.png'
import OP03 from '../../assets/extensions/OP03.png'
import OP02 from '../../assets/extensions/OP02.png'
import OP01 from '../../assets/extensions/OP01.png'
import EB01 from '../../assets/extensions/EB01.png'

function Extension (props) {
    const imageMapping = {
        OP10: OP10,
        OP09: OP09,
        OP08: OP08,
        OP07: OP07,
        OP06: OP06,
        OP05: OP05,
        OP04: OP04,
        OP03: OP03,
        OP02: OP02,
        OP01: OP01,
        EB01: EB01
    };

    const imageToDisplay = imageMapping[props.code] || OP09;
    const Wrapper = props.isLink ? Link : 'div';
    const wrapperProps = props.isLink ? { to: `/collection/${props.slug}`, className: 'animate-fadeInScale'} : {className: 'w-full '};

    return (
        <Wrapper {...wrapperProps}>
            <div
                className={`flex flex-col items-center justify-center rounded-2xl shadow px-2 py-3 text-center font-bold relative mx-auto ${props.isLink ? 'cursor-pointer' : ''}`}
                style={{
                    backgroundImage: `url(${imageToDisplay})`,
                    backgroundPosition: 'center',
                    height: '200px',
                    maxWidth: '800px'
                }}
            >
                <div
                    className={`rounded-2xl opacity-80 transition ${props.isLink ? 'hover:opacity-70' : ''}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgb(0, 0, 0)',
                        zIndex: 10
                    }}
                />
                <div className="z-20 flex flex-col items-center justify-center">
                    <span>{props.name}</span>
                    <span>{props.code}</span>
                    {props.total &&
                        <span>{props.count ? props.count : 0} / {props.total ? props.total : 0}</span>
                    }
                </div>
            </div>
        </Wrapper>
    )
}

export default Extension