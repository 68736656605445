import image404 from '../../assets/404.png';
import {Link} from "react-router-dom";

function Error() {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-5xl mb-12 text-center">Oups ! Cette page est partie chercher le One Piece avec Gold Roger.</h1>
            <img className="w-80 rounded" src={image404} alt="404"/>
            <Link className="mt-10 px-4 py-2 bg-white text-blue-500 rounded hover:bg-gray-200 font-bold" to="/">Retourner a l'accueil</Link>
        </div>
    )
}

export default Error