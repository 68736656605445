import React from "react";

const StatsSkeleton = () => {
    return (
        <div>
            <span
                className="m-5 p-3 rounded-2xl shadow-xl sm:w-full md:w-auto flex flex-col items-center justify-center text-center relative animate-pulse fadeInScaleFast"
                style={{
                    backgroundImage: 'linear-gradient(to bottom, #e0e0e0, #cfcfcf)',
                    backgroundPosition: 'center',
                    width: '130px',
                    height: '130px'
                }}
            >
                <div className="z-20 flex flex-col items-center justify-center font-bold space-y-2">
                    <div className="h-6 w-12 bg-gray-400 rounded"></div>
                    <div className="h-4 w-16 bg-gray-400 rounded"></div>
                </div>
            </span>
        </div>
    );
};

export default StatsSkeleton;
