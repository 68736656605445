import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'apiKey': API_KEY,
    },
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['Edition'] = localStorage.getItem('selectedEdition') || 'ja';

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;
