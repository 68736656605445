import React, { useState, useEffect } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineSearch, AiOutlineUserAdd , AiOutlineArrowLeft} from 'react-icons/ai'
import axiosInstance from '../../utils/axiosInstance'

import defaultImage from "../../assets/profiles/default.png"
import sanjiLove from '../../assets/profiles/sanji_love.png'
import chopper from '../../assets/profiles/chopper.png'
import nami from '../../assets/profiles/nami.png'
import zoro from '../../assets/profiles/zoro.png'
import FriendCollection from "../../components/Friend/FriendCollection";

const profileImageMap = {
    default: defaultImage,
    sanji_love: sanjiLove,
    chopper: chopper,
    nami: nami,
    zoro: zoro
}

const FriendList = () => {
    const [friends, setFriends] = useState([])
    const [friendRequests, setFriendRequests] = useState([])
    const [selectedFriend, setSelectedFriend] = useState(null)
    const [searchResults, setSearchResults] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [loadingSearch, setLoadingSearch] = useState(false)

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const response = await axiosInstance.get('/friends')
                setFriends(response.data || [])
            } catch (err) {
                console.error('Erreur lors de la récupération des amis:', err)
                setFriends([])
            }
        }

        const fetchRequests = async () => {
            try {
                const response = await axiosInstance.get('/friends/requests')
                setFriendRequests(response.data || [])
            } catch (err) {
                console.error('Erreur lors de la récupération des requêtes d\'amis:', err)
                setFriends([])
            }
        }

        fetchFriends()
        fetchRequests()
    }, [])

    const handleAddFriend = async (uniqid) => {
        console.log(uniqid);
        const newFriend = friendRequests.find((req) => req.uniqid === uniqid)
        if (newFriend) {
            setFriends([...friends, newFriend])
            setFriendRequests(friendRequests.filter((req) => req.uniqid !== uniqid))
            try {
                await axiosInstance.get(`/friends/accept/${uniqid}`)
            } catch (err) {
                console.error('Erreur lors de la récupération de la requête d\'ami:', err)
            }
        }
    }

    const handleRemoveFriendRequest = async (uniqid) => {
        const newFriend = friendRequests.find((req) => req.uniqid === uniqid)
        if (newFriend) {
            setFriendRequests(friendRequests.filter((req) => req.uniqid !== uniqid))
            try {
                await axiosInstance.get(`/friends/decline/${uniqid}`)
            } catch (err) {
                console.error('Erreur lors de la récupération de la requête d\'ami:', err)
            }
        }
    }

    const handleSendRequest = (uniqid) => {
        const fetchSendRequest = async () => {
            try {
                await axiosInstance.get(`/friends/send/${uniqid}`)
            } catch (err) {
                console.error('Erreur lors de l\'envoie de la requête d\'ami:', err)
            }
        }
        fetchSendRequest()
        setSearchResults(searchResults.filter((user) => user.uniqid !== uniqid))
    }

    useEffect( () => {
        if (searchQuery.trim() === '') {
            setSearchResults([])
            return
        }

        setLoadingSearch(true)

        try {
            const fetchQuery = async () => {
                try {
                    const response = await axiosInstance.post('/friends/users', {searchQuery})
                    setSearchResults(response.data || [])
                } catch (err) {
                    console.error('Erreur lors de la récupération des requêtes d\'amis:', err)
                    setFriends([])
                }
            }

            fetchQuery()
        } catch (error) {
            console.error('Erreur lors de la recherche:', error)
        } finally {
            setLoadingSearch(false)
        }
    }, [searchQuery])

    const handleRemoveFriend = (uniqid) => {
        setFriends(friends.filter((friend) => friend.uniqid !== uniqid))
    }

    const handleViewCollection = (friend) => {
        setSelectedFriend(friend)
    }

    const handleBackToFriends = () => {
        setSelectedFriend(null)
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4 text-center">Communauté</h1>

            {!selectedFriend ? (
                <>
                    <div className="mb-6">
                        <h2 className="text-xl font-semibold mb-2">Rechercher un ami</h2>
                        <div className="flex items-center mb-4">
                            <input
                                type="text"
                                placeholder="Entrez un nom..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="flex-1 p-2 border border-gray-300 rounded-l text-black"
                            />
                        </div>
                        <ul className="space-y-2">
                            {searchResults.length === 0 && searchQuery && !loadingSearch && (
                                <p className="text-gray-500">Aucun utilisateur trouvé.</p>
                            )}
                            {searchResults.map((user) => (
                                <li
                                    key={user.uniqid}
                                    className="flex justify-between items-center bg-gray-100 p-3 rounded shadow"
                                >
                                    <div className="flex items-center space-x-4">
                                        <img
                                            src={profileImageMap[user.image]}
                                            alt={`${user.username} profile`}
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <span className="text-black">{user.username}</span>
                                    </div>
                                    <button
                                        onClick={() => handleSendRequest(user.uniqid)}
                                        className="p-2 bg-green-500 text-white rounded-xl hover:bg-green-700"
                                    >
                                        <AiOutlineUserAdd size={25} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mb-6">
                        <h2 className="text-xl font-semibold mb-2">Mes amis</h2>
                        {friends.length === 0 ? (
                            <p className="text-gray-500">Vous n'avez pas encore d'amis.</p>
                        ) : (
                            <ul className="space-y-2">
                                {friends.map((friend) => (
                                    <li
                                        key={friend.uniqid}
                                        className="flex justify-between items-center bg-gray-100 p-3 rounded shadow"
                                    >
                                        <div className="flex items-center space-x-4">
                                            <img
                                                src={profileImageMap[friend.image]}
                                                alt={`${friend.username} profile`}
                                                className="w-12 h-12 rounded-full"
                                            />
                                            <span className="text-black">{friend.username}</span>
                                        </div>
                                        <div className="space-x-2">
                                            <button
                                                onClick={() => handleViewCollection(friend)}
                                                className="p-2 bg-blue-500 text-white rounded-xl hover:bg-blue-700 mx-1"
                                            >
                                                <AiOutlineSearch size={25}/>
                                            </button>
                                            <button
                                                onClick={() => handleRemoveFriend(friend.uniqid)}
                                                className="p-2 bg-red-500 text-white rounded-xl hover:bg-red-700 mx-1"
                                                aria-label="Retirer"
                                            >
                                                <AiOutlineClose size={25} />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div>
                        <h2 className="text-xl font-semibold mb-2">Demandes d'amis</h2>
                        {friendRequests.length === 0 ? (
                            <p className="text-gray-500">Aucune demande d'ami en attente.</p>
                        ) : (
                            <ul className="space-y-2">
                                {friendRequests.map((request) => (
                                    <li
                                        key={request.uniqid}
                                        className="flex justify-between items-center bg-gray-100 p-3 rounded shadow"
                                    >
                                        <div className="flex items-center space-x-4">
                                            <img
                                                src={profileImageMap[request.image]}
                                                alt={`${request.username} profile`}
                                                className="w-12 h-12 rounded-full"
                                            />
                                            <span className="text-black">{request.username}</span>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => handleAddFriend(request.uniqid)}
                                                className="p-2 bg-green-500 text-white rounded-xl hover:bg-green-700 mx-1"
                                                aria-label="Accepter"
                                            >
                                                <AiOutlineCheck size={25}/>
                                            </button>
                                            <button
                                                onClick={() => handleRemoveFriendRequest(request.uniqid)}
                                                className="p-2 bg-red-500 text-white rounded-xl hover:bg-red-700 mx-1"
                                                aria-label="Refuser"
                                            >
                                                <AiOutlineClose size={25} />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            ) : (
                <div className="text-center">
                    <button
                        onClick={handleBackToFriends}
                        className="text-blue-500 hover:text-blue-700 flex mb-5"
                    >
                        <AiOutlineArrowLeft size={25}/> <span className="ms-2">Retour</span>
                    </button>
                    <FriendCollection usernameFriend={selectedFriend.username} imageFriend={profileImageMap[selectedFriend.image]} uniqidFriend={selectedFriend.uniqid}/>
                </div>
            )}
        </div>
    )
}

export default FriendList
